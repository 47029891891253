<template>
  <div class="profile-dialog pt-4 relative over-hidden">
    <div class="close-icon">
      <v-icon color="primary" @click="$emit('close')">mdi-close</v-icon>
    </div>
    <div class="text-center mx-7 pb-1 mb-5">
      <span class="font-30 primary--text font-500">{{
        $t("updateProfile")
      }}</span>
      <!-- <p class="font-20 black--text mb-0 mt-3">{{$t('changeEmailText')}}</p> -->
    </div>
    <div class="px-10 text-center">
      <v-form v-model="valid" @submit.prevent="saveHandler" ref="emailForm">
        <v-row>
          <v-col cols="12">
            <div class="img_profile" style="z-index: 100">
              <img
               
                class="cursor-pointer"
                :src="profileImage"
                style="border-radius: 50%"
                width="120"
                height="120"
              />
            </div>
            <div class="camera"  @click="$refs.imageInput.click()">
              <img
                :src="require('@/assets/images/global/camera.png')"
                width="45"
                height="40"
              />
            </div>

            <input
              accept="image/*"
              type="file"
              name="image-ngo"
              style="display: none"
              ref="imageInput"
              @change="imageUploaded"
            />

            <!-- {{object}} -->
          </v-col>
          <v-col cols="12">
            <base-input
              type="name"
              :placeholder="$t('newEmail')"
              v-model="object.first_name"
            ></base-input>
            <!-- {{object}} -->
          </v-col>
          <v-col cols="12">
            <base-input
              type="name"
              :placeholder="$t('newEmail')"
              v-model="object.last_name"
            ></base-input>
            <!-- {{object}} -->
          </v-col>
          <v-col cols="12">
            <base-input
              type="email"
              :placeholder="$t('newEmail')"
              v-model="object.email"
              :rules="$helper.emailRules"
            ></base-input>
            <!-- {{object}} -->
          </v-col>
        </v-row>
        <v-btn
          class="c-btn mb-10 mt-10"
          color="primary"
          min-width="150"
          height="38"
          type="submit"
          ><span class="white--text font-300 font-20">{{
            $t("save")
          }}</span></v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    obj: {
      type: Object,
    },
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    valid: false,
    object: {
      email: "",
      first_name: "",
      last_name: "",
      image: null,
      isEmpty: false,
      keyUpdated: false,
    },
    emageProfile:null,
    imageSubmit:null
  }),
  methods: {
    imageUploaded(e) {
      this.emageProfile = URL.createObjectURL(e.target.files[0]);
      this.imageSubmit = e.target.files[0];

    },
    async saveHandler() {
      let valid = this.$refs.emailForm.validate();
      if (!valid) return;
      let formData = new FormData();
      formData.append("email", this.object.email);
      formData.append("first_name", this.object.first_name);
      formData.append("last_name", this.object.last_name);
      if (this.emageProfile) {
        formData.append("image", this.imageSubmit);
      }
      // send request
      const res = await this.$axios.post("/updateProfile", formData);
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Email updated successfully",
          color: "success",
        });
        this.$emit("close");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    initState() {},
  },
  computed:{
    profileImage(){
      return this.emageProfile || this.object.image
    }
  },
  created() {
    this.object = JSON.parse(JSON.stringify(this.obj));
  },
};
</script>

<style lang="scss">
.profile-dialog {
  .camera {

padding-top: 40px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #cccccc50;
    position: absolute;
    top: 73px;
    left: 35%;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    display: none;
    &:hover {
      display: block;
    }
  }
  .img_profile {
    filter: grayscale(30%);

    &:hover + .camera {

      transition: all 0.2s ease-in-out;

      display: block;
    }
  }
}
</style>

<template>
  <div class="profile-user max-width">
    <v-row>
      <v-col cols="12" md="4" class="d-flex">
        <img
          :key="keyUpdated"
          class="object-cover profile_img"
          width="110px"
          height="110px"
          :src="
            file.url
              ? file.url
              : obj.image && obj.image != '#'
              ? obj.image
              : userImage
          "
        />
        <!-- <base-upload-text
          @change="uploadImage"
          v-if="isEdit && !file.url"
          :hasImage="false"
        ></base-upload-text> -->
        <v-col class="align-center">
          <h3 v-if="obj.first_name">
            {{ obj.first_name + " " + obj.last_name }}
            <img
              @click="emailDialog = true"
              :src="require('@/assets/images/global/edit.png')"
              width="16"
              height="16"
              class="mx-2"
            />
          </h3>
          <h5 class="email_text">{{ obj.email }}</h5>
          <h5 class="email_text">{{ obj.phone }}</h5>
        </v-col>
      </v-col>
      <v-col cols="12" md="8" style="min-height: 300px;">
        <v-row>
          <v-col cols="12" md="6" v-for="(item, i) in wallets" :key="i">
            <v-card
              class="d-flex align-end pa-4"
              style="box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09) !important"
            >
              <img
                class="mx-5"
                width="37"
                height="35"
                :src="require('@/assets/images/profile/wallet_tapl.png')"
                alt=""
              />
              <div>
                <span class="mx-2">{{ i == 0 ? $t('in') : $t('out') }}</span>

                <img
                  :src="
                    require(`@/assets/images/icons/${
                      i == 0 ? 'arrow_dawn' : 'arrow_top'
                    }.png`)
                  "
                  width="10"
                  height="10"
                  alt=""
                />
                <span class="font-25 black--text font-600 d-block"
                  >{{ $t("total") }}
                  <span
                    class="primary--text font-30"
                    style="font-family: bold"
                    >{{ i == 0 ? total_in : total_out }}</span
                  >
                  <span>USD</span>
                </span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- <v-row class="mt-4">
      <v-col cols="12" md="6">
        <span class="font-20 black--text">{{ $t("firstName") }}</span>
        <base-input
          class="mt-2"
          v-if="isEdit"
          :placeholder="$t('firstName')"
          v-model="obj.first_name"
        ></base-input>
      </v-col>

      <v-col cols="12" md="6">
        <span class="font-20 black--text">{{ $t("lastName") }}</span>
        <base-input
          class="mt-2"
          v-if="isEdit"
          :placeholder="$t('lastName')"
          v-model="obj.last_name"
        ></base-input>
      </v-col>
    </v-row> -->

    <!-- <div class="d-flex align-center justify-center mt-8" v-if="!isEdit">
      <v-btn
        class="c-btn mx-1"
        color="secondary"
        height="38"
        min-width="150"
        @click="editOpenHandler"
        ><span class="white--text font-20 font-300">{{
          $t("editProfile")
        }}</span></v-btn
      >

      <v-btn
        class="c-btn mx-1"
        color="secondary"
        height="38"
        min-width="150"
        @click="passwordDialog = true"
        ><span class="white--text font-20 font-300">{{
          $t("changePassword")
        }}</span></v-btn
      >
      <v-btn
        class="c-btn mx-1"
        color="secondary"
        height="38"
        min-width="150"
        @click="emailDialog = true"
        ><span class="white--text font-20 font-300">{{
          $t("updateEmail")
        }}</span></v-btn
      >
    </div> -->

    <!-- <div class="d-flex align-center justify-center mt-8" v-if="isEdit">
      <v-btn
        class="c-btn mx-1"
        color="secondary"
        height="38"
        min-width="150"
        @click="getData"
        ><span class="white--text font-20 font-300">{{
          $t("cancel")
        }}</span></v-btn
      >

      <v-btn
        class="c-btn mx-1"
        color="secondary"
        height="38"
        min-width="150"
        @click="saveHandler"
        ><span class="white--text font-20 font-300">{{
          $t("save")
        }}</span></v-btn
      >
    </div> -->
    <!-- <v-row>
      <v-col cols="4" md="2">
        <img
          :key="keyUpdated"
          class="object-cover radius-circle"
          width="120px"
          height="120px"
          :src="
            file.url
              ? file.url
              : obj.image && obj.image != '#'
              ? obj.image
              : userImage
          "
        />
        <base-upload-text
          @change="uploadImage"
          v-if="isEdit && !file.url"
          :hasImage="false"
        ></base-upload-text>
      </v-col>
      <v-col cols="8" md="4">
        <div class="d-flex align-center">
          <div>
            <base-input
              class="mt-2"
              v-if="isEdit"
              :placeholder="$t('lastName')"
              v-model="obj.first_name"
            ></base-input>
            <div class="py-3 c-btn white px-4 mt-2" v-if="!isEdit">
              <span class="font-18 black--text">{{ obj.first_name }}</span>
            </div>
          </div>
          <div>
            <base-input
              class="mt-2"
              v-if="isEdit"
              :placeholder="$t('firstName')"
              v-model="obj.last_name"
            ></base-input>
            <div class="py-3 c-btn white  px-4 mt-2" v-if="!isEdit">
              <span class="font-18 black--text">{{ obj.last_name }}</span>
            </div>
          </div>
        </div>
        <span class="lightblue--text font-20 d-block">{{ obj.email }}</span>
        <span class="lightblue--text font-20 d-block">{{ obj.phone }}</span>
      </v-col>
      <v-col cols="6" md="3" v-if="wallets[0].value">
        <div class="box-shadow py-6">
          <span class="lightblue--text font-20 d-block">{{
            wallets[0].value
          }}</span>
        </div>
      </v-col>
      <v-col cols="6" md="3" v-if="wallets[1].value">
        <div class="box-shadow py-6">
          <span class="lightblue--text font-20 d-block">{{
            wallets[1].value
          }}</span>
        </div>
      </v-col>
    </v-row> -->
    <!-- password dialog -->
    <v-dialog v-model="passwordDialog" max-width="400" content-class="white">
      <change-password-dialog
        @close="closeHandler"
        :email="obj.email"
        :key="passwordDialog"
      ></change-password-dialog>
    </v-dialog>

    <!-- email dialog -->
    <v-dialog v-model="emailDialog" max-width="400" content-class="white">
      <change-email-dialog
        @close="closeEmailHandler"
        :obj.sync="obj"
        :key="emailDialog"
      ></change-email-dialog>
    </v-dialog>
  </div>
</template>

<script>
import ChangeEmailDialog from "../../../components/dialogs/ChangeEmail.vue";
import ChangePasswordDialog from "../../../components/dialogs/ChangePassword.vue";
export default {
  components: { ChangePasswordDialog, ChangeEmailDialog },
  data: () => ({
    passwordDialog: false,
    emailDialog: false,
    isEdit: false,
    total_in: null,
    total_out: null,
    obj: {},
    file: {},
    keyUpdated: false,
    userImage: require("@/assets/images/global/user-default.jpg"),
    wallets: [{}, {}],
  }),
  methods: {
    closeHandler() {
      this.passwordDialog = false;
    },
    async closeEmailHandler() {
      await this.getData();
      this.emailDialog = false;
    },
    uploadImage(file) {
      this.file.url = URL.createObjectURL(file);
      this.file.file = file;
      this.keyUpdated = !this.keyUpdated;
    },
    async saveHandler() {
      let formData = new FormData();
      formData.append("first_name", this.obj.first_name);
      formData.append("last_name", this.obj.last_name);

      // check if new image uploaded
      if (this.file.url) {
        formData.append("image", this.file.file);
      }

      // send request
      const res = await this.$axios.post("/updateProfile", formData);
      if (res.data) {
        this.$store.dispatch("showSnack", {
          text: "Edited successfully",
          color: "success",
        });
        this.isEdit = false;
        await this.$store.dispatch("getProfile");
      } else {
        this.$store.dispatch("showSnack", {
          text: res.message,
          color: "error",
        });
      }
    },
    editOpenHandler() {
      this.isEdit = true;
      this.file = { url: "" };
    },
    async getData() {
      this.isEdit = false;
      this.file = { url: "" };
      let { data } = await this.$axios.get("/myProfile");
      if (data) {
        this.obj = data.data;
      }
    },
    async getWallet() {
      await this.$axios.get("/getMyWallet").then((data) => {
        this.total_in = data.data.data.total_in;
        this.total_out = data.data.data.total_out;
      });
    },
  },
  async created() {
    await this.getData();
    await this.getWallet();
  },
};
</script>

<style lang="scss">
.profile-user {
  .profile_img {
    border-radius: 50%;
  }
  .email_text {
    color: #89bbcb;
  }
  // .upload-text {
  //   position: absolute;
  //   top: 0px;
  //   left: 0px;
  //   width: 100%;
  //   height: 100%;
  // }
}
</style>

<template>
  <div class="password-dialog pt-4 relative over-hidden">
    <div class="close-icon">
      <v-icon color="primary" @click="$emit('close')">mdi-close</v-icon>
    </div>
    <div class="text-center mx-7 pb-1 mb-5">
      <span class="font-30 primary--text font-500">{{
        $t("changePassowrd")
      }}</span>
      <p class="font-20 black--text mb-0 mt-3">
        {{ $t("changePasswordText") }}
      </p>
    </div>
    <div class="px-10 text-center">
      <v-form v-model="valid" @submit.prevent="saveHandler" ref="passowrdForm">
        <v-row>
          <v-col cols="12">
            <base-input
              type="password"
              placeholder="Old Password"
              v-model="obj.oldPassword"
            ></base-input>
          </v-col>
          <v-col cols="12">
            <base-input
              type="password"
              placeholder="New Password"
              v-model="obj.newPassword"
            ></base-input>
          </v-col>
          <v-col cols="12">
            <base-input
              type="password"
              placeholder="Confirm New Password"
              v-model="obj.password_confirmation"
            ></base-input>
          </v-col>
        </v-row>
        <v-btn
          class="c-btn mb-10 mt-10"
          color="primary"
          min-width="150"
          height="38"
          type="submit"
          ><span class="white--text font-300 font-20">{{$t('save')}}</span></v-btn
        >
      </v-form>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    email: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    valid: false,
    obj: {},
  }),
  methods: {
    async saveHandler() {
      // let valid = this.$refs.passowrdForm.validate();
      // if (this.obj.newPassword != this.obj.password_confirmation) {
      //   this.$store.dispatch("showSnack", {
      //     text: "Confirm password must be same with new!",
      //     color: "error",
      //   });
      // } else {
      //   if (!valid) return;
      //   let formData = new FormData();
      //   formData.append("oldPassword", this.obj.oldPassword);
      //   formData.append("new_password", this.obj.newPassword);
      //   formData.append(
      //     "password_confirmation",
      //     this.obj.password_confirmation
      //   );

      //   // send request
      //   const res = await this.$axios.post("/changePassword", formData);
      //   if (res.data) {
      //     this.$store.dispatch("showSnack", {
      //       text: "Password Edited successfully",
      //       color: "success",
      //     });
      //     await this.$axios.post("/auth/logout");
      //     this.$store.dispatch("removeUser");

      //     // login after change password
      //     const res = await this.$axios.post("/auth/login", {
      //       email: this.email,
      //       password: this.obj.newPassword
      //     });
      //     if (res.data) {
      //       res.data.userLogged = true;
      //       this.$store.dispatch("setUser", res.data);
      //       this.$store.dispatch("showSnack", {
      //         text: "logged successfully",
      //         color: "success",
      //       });
      //       this.$axios.defaults.headers.common = {
      //         Authorization: `Bearer ${localStorage.getItem("token")}`,
      //       };
      //       let fData = new FormData();
      //       fData.append("token", this.fcmToken);
      //       await this.$axios.post("/save-token", fData);
      //       this.$router.replace("/");
      //     }
      //   }else {
      //     this.$store.dispatch("showSnack", {
      //       text: res.message,
      //       color: "error",
      //     });
      //   }
      // }
    },
  },
};
</script>

<style lang="scss"></style>
